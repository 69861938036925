import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgBaseAngularMaterialModule } from '../ng-base-angular-material.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { BlankComponent } from './blank/blank.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogContainerComponent } from './error-dialog/error-dialog-container.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { JasperReportComponent } from './jasper/jasper-report/jasper-report.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { NgBasePipeModule } from '../pipe/ng-base-pipe.module';


@NgModule({
	declarations: [
		AlertDialogComponent,
		BlankComponent,
		ConfirmationDialogComponent,
		ErrorDialogComponent,
		ErrorDialogContainerComponent,
		JasperReportComponent,
		LoadingIndicatorComponent,
		PageNotFoundComponent,
		SnackBarComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		NgBaseAngularMaterialModule,
		NgBasePipeModule
	],
	exports: [
		BlankComponent,
		ConfirmationDialogComponent,
		ErrorDialogComponent,
		ErrorDialogContainerComponent,
		JasperReportComponent,
		LoadingIndicatorComponent,
		PageNotFoundComponent,
		SnackBarComponent,
	]
})
export class NgBaseComponentModule { }

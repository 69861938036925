<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content class="mat-typography">
	{{data.message}}
</div>
<div *ngIf="data.fatal" mat-dialog-content class="mat-typography">
	Vuelva a iniciar sesión.
</div>
<mat-dialog-actions align="end">
	<button mat-raised-button mat-dialog-close color="primary">Aceptar</button>
	<!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
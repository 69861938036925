import { NativeDateAdapter } from '@angular/material/core';
import { DateUtil } from './date-util';

export class DateAdapterDDMM extends NativeDateAdapter {

	override parse(value: string) {
		let it = value.split('/');
		if (it.length == 3) {
			return DateUtil.dateToLocaleZeroHour(
				new Date(+it[2], +it[1] - 1, +it[0], 12)
			);
		}
		return null;
	}

//VER PHARMA
	/*override format(date: Date, displayFormat: Object) {
		console.log("tututu");
		return ('0' + date.getDate()).slice(-2) + '/' +
			('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
	}*/
	format(date: Date, displayFormat: Object) {
		// console.log("tututu");
		// console.log("1111111 " + date.toString());

		let d = date;
		d = new Date(d.getTime() + (d.getTimezoneOffset() * 60000));
		// console.log("1111111 " + d.toString());

		return ('0' + d.getDate()).slice(-2) + '/' +
			('0' + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear();
	}

	/**
	 * Gets the year component of the given date.
	 * @param date The date to extract the year from.
	 * @returns The year component.
	 */
	getYear(date: Date): number {
		let d = date;
		d = new Date(d.getTime() + (d.getTimezoneOffset() * 60000));

		return + d.getFullYear();
	}

	/**
	 * Gets the month component of the given date.
	 * @param date The date to extract the month from.
	 * @returns The month component (0-indexed, 0 = January).
	 */
	getMonth(date: Date): number {
		let d = date;
		d = new Date(d.getTime() + (d.getTimezoneOffset() * 60000));

		return +('0' + (d.getMonth())).slice(-2);
	}

	/**
	 * Gets the date of the month component of the given date.
	 * @param date The date to extract the date of the month from.
	 * @returns The month component (1-indexed, 1 = first of month).
	 */
	getDate(date: Date): number {
		let d = date;
		d = new Date(d.getTime() + (d.getTimezoneOffset() * 60000));

		return +('0' + d.getDate()).slice(-2);
	}


}

import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

import { AppInitService } from '../_base/service/app-init.service';
import { LoggerService } from '../_base/service/logger/logger.service';
import { UserMenuService } from '../_base/service/menu/user-menu.service';


@Injectable({
	providedIn: 'root'
})
export class AppInitImplService extends AppInitService {

	constructor(protected logger: LoggerService,
		private userMenuService: UserMenuService
	) {
		super(logger);
		this.logger.i("app-init.service: constructed");
	}

	init(redirectUrl?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			Promise.all([
				this.userMenuService.init(),
			])
				.then((res) => {
					this.appReady.next(true);
					if (redirectUrl == null) {
						redirectUrl = "home";
					}
					if (!environment.production && environment.homeUrl != null) {
						resolve(environment.homeUrl);
					} else {
						resolve(redirectUrl);
						// if(this.dealService.hasDeals()){
						// 	resolve("deals");
						// }else{
						// 	resolve("purchase");
						// }		
					}
				})
				.catch((error) => {
					this.logger.e("app-init.service: " + JSON.stringify(error, null, 2));
					reject("Error inicializando aplicación.");
				});
		});
	}

	setReady(value: boolean) {
		this.appReady.next(value);
	}

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerService } from '../../service/logger/logger.service';
import { ErrorDialogService } from './error-dialog.service';
import { ErrorDialogComponent } from './error-dialog.component';

@Component({
	selector: 'ng-base-error-dialog-container',
	template: ''
})
export class ErrorDialogContainerComponent implements OnInit {

	private dialogRef: MatDialogRef<any>;
	// private title: string = "Error";
	// private message: string;

	constructor(public dialog: MatDialog,
				private logger: LoggerService,
				private errorDialogService: ErrorDialogService

				) {
		this.logger.i("error-dialog-container.component: constructed");
	}

	ngOnInit(){
		this.errorDialogService.errorEmitter.subscribe((obj:any) =>{
			this.logger.i("error-dialog.component: " + JSON.stringify(obj, null, 2));
			this.open(obj);
		});
	}

	open(obj: any) {		
		this.dialogRef = this.dialog.open(ErrorDialogComponent, 
			{ data: obj, 
			  disableClose: true, 
			  minWidth: '20vw', 
			  minHeight: '10vh' });
	}

}

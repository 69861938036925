<div class="container">

	<form *ngIf="paramsForm && htmlStack.length == 0" [formGroup]="paramsForm" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutGap="12px" autocomplete="off">
		<h1>Consulta {{report.name | splitCamelcase}}</h1>
		
		<div fxFlex formArrayName="params"
		*ngFor="let param of paramsForm.controls.params['controls']; let i = index">
		
		<ng-container *ngIf="param.controls.options.value.length == 0">
			<ng-container [formGroupName]="i" [ngSwitch]="param.controls.type.value">
				<ng-container *ngSwitchCase="'Integer'">
					<mat-form-field floatLabel="always" fxFlex>
						<mat-label>{{param.controls.label.value}}</mat-label>
						<input matInput type="number"
						formControlName="value">
						<mat-error *ngIf="submitted && param.invalid">
							Ingresar un valor.
						</mat-error>
					</mat-form-field>
				</ng-container>
				
				<ng-container *ngSwitchCase="'Boolean'">
					<mat-checkbox formControlName="value" fxFlex labelPosition="before">
						{{param.controls.label.value}}
					</mat-checkbox>
				</ng-container>
				
				<ng-container *ngSwitchCase="'TimeStamp'">
					<mat-form-field floatLabel="always" fxFlex>
						<mat-label>{{param.controls.label.value}}</mat-label>
						<input matInput [matDatepicker]="picker"
						formControlName="value">
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
						<mat-error *ngIf="submitted && param.invalid">
							Ingresar un valor.
						</mat-error>
					</mat-form-field>
				</ng-container>
				
				<ng-container *ngSwitchDefault>
					<mat-form-field floatLabel="always" fxFlex>
						<mat-label>{{param.controls.label.value}}</mat-label>
						<input matInput type="text" formControlName="value">
						<mat-error *ngIf="submitted && param.invalid">
							Ingresar un valor.
						</mat-error>
					</mat-form-field>
				</ng-container>
			</ng-container>
		</ng-container>
		
		<ng-container *ngIf="param.controls.options.value.length > 0">
			<ng-container [formGroupName]="i">
				<!-- <ng-container *ngSwitchCase="'StringListMS'">
					<mat-label>{{param.controls.label.value}}</mat-label>
					<mat-select multiple fxFlex [placeholder]=param.controls.label.value formControlName="valueSet">
						<mat-option *ngFor="let option of param.controls.options "
						[value]="option">{{option}}</mat-option>
					</mat-select>
				</ng-container>
				
				<ng-container *ngSwitchCase="'StringSS'">
					<mat-label>{{param.controls.label.value}}</mat-label>
					<mat-select fxFlex [placeholder]=param.controls.label.value formControlName="value">
						<mat-option *ngFor="let option of param.controls.options "
						[value]="option">{{option}}</mat-option>
					</mat-select>
				</ng-container> -->
				
				<ng-container>
					<mat-form-field floatLabel="always" fxFlex>
						<mat-label>{{param.controls.label.value}}</mat-label>
						<mat-select formControlName="value">
							<mat-option *ngFor="let option of param.controls.options.value" [value]="option">
								{{option.description}}</mat-option>
							</mat-select>
							<mat-error *ngIf="submitted && param.invalid">
								Seleccionar un valor de la lista.
							</mat-error>
						</mat-form-field>
						<!-- <pre>
							{{param.controls.options.value | json}}
						</pre> -->
					</ng-container>
					
				</ng-container>
				
			</ng-container>
			
			
		</div>
		
		<div fxFlex fxLayoutAlign="space-around center" class="button-row padding-dbl-top" fxLayoutGap="16px">
			<button *ngIf="report.html" class="button-horizontal-form" fxFlex type="submit" mat-raised-button color="primary" (click)="out='html'">consultar</button>
			<button *ngIf="report.xls" class="button-horizontal-form" fxFlex type="submit" mat-raised-button color="primary" (click)="out='xls'">excel</button>
			<button *ngIf="report.pdf" class="button-horizontal-form" fxFlex type="submit" mat-raised-button color="primary" (click)="out='pdf'">pdf</button>
		</div>
		<!-- <pre>
			{{paramsForm.value | json}}
		</pre> -->
	</form>

	<mat-grid-list cols="4" rowHeight="40px" gutterSize="8" class="width-100 padding" *ngIf="htmlStack.length > 0">
		<mat-grid-tile>
			<button mat-raised-button color="primary" type="button" (click)="back()" *ngIf="htmlStack.length > 0">
				<mat-icon>chevron_left</mat-icon>
				<span *ngIf="!handsetPortraitMode">anterior</span>
			</button>
		</mat-grid-tile>
		<mat-grid-tile>

		</mat-grid-tile>
		<mat-grid-tile>
			<button *ngIf="report.xls" class="button-horizontal-form" fxFlex type="button" mat-raised-button color="primary" (click)="downloadXls();">excel</button>
		</mat-grid-tile>
		<mat-grid-tile>
			<button *ngIf="report.pdf" class="button-horizontal-form" fxFlex type="button" mat-raised-button color="primary" (click)="downloadPdf();">pdf</button>
		</mat-grid-tile>
	</mat-grid-list>

	<div *ngIf="htmlStack.length > 0" [innerHtml]="sanitizer.bypassSecurityTrustHtml(htmlStack[htmlStack.length - 1])"></div>
</div>

import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../../service/logger/logger.service';

@Component({
	selector: 'app-blank',
	templateUrl: './blank.component.html',
	styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {

	constructor(protected logger: LoggerService) {
		this.logger.i("blank.component: constructed");
	}

	ngOnInit() {
		
	}

}

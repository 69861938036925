import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerService } from '../../service/logger/logger.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
	selector: 'ng-base-alert-dialog',
	templateUrl: './alert-dialog.component.html',
	styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

	constructor(private logger: LoggerService,
		public dialog: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.logger.i("confirmation-dialog.component: constructed");
	}

	getOkLabel() {
		return this.data.okLabel ? this.data.okLabel : "OK";
	}

	ngOnInit() {
	}

	dismiss(): void {
		this.dialog.close(false);
	}

}

export class DateUtil {

	static dateToLocale(date: Date): Date {
		return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
	}

	static dateToLocaleZeroHour(date: Date): Date {
		let d = new Date(date);
		d.setHours(0);
		d.setMinutes(0);
		d.setSeconds(0);
		d.setMilliseconds(0);
		return d;
	}

	static stringToLocale(str: string): Date{
		let date = new Date(str);
		return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
	}

	static adaptForServices(str: string): string{
		return str.substring(0, 10) + "T" + "00:00:00";
	}
}

import { NgModule } from '@angular/core';
import { SplitCamelcasePipe } from './split-camelcase.pipe';

@NgModule({
	declarations: [
		SplitCamelcasePipe,
	],
	exports: [
		SplitCamelcasePipe, 
	]
})
export class NgBasePipeModule { }

import { Injectable } from '@angular/core';
import { LoggerService } from '../../service/logger/logger.service';
import { RestAuthApiService } from '../../service/rest-api/rest-auth-api.service';

@Injectable({
	providedIn: 'root'
})
export class JasperReportService {

	paramsEndpoint = "be/report/params";

	constructor(private logger: LoggerService,
		private apiService: RestAuthApiService) {
		this.logger.i("report.service: constructed");
	}

	getParams(reportId: number): Promise<any> {
		return this.apiService.get(this.paramsEndpoint, {reportId: reportId});
		// return Promise.resolve({
		// 	"data": [
		// 		{
		// 			"label": "Deposito",
		// 			"name": "p_deposito",
		// 			"options": [
		// 				{
		// 					"description": " Todos",
		// 					"id": 0
		// 				},
		// 				{
		// 					"description": "PA-LOG MULTI PROD",
		// 					"id": 350
		// 				},
		// 				{
		// 					"description": "PA-LOGEXPORT",
		// 					"id": 352
		// 				},
		// 				{
		// 					"description": "PA-LOGEXPORT DEV.BST",
		// 					"id": 6
		// 				},
		// 				{
		// 					"description": "PALLET FRAG",
		// 					"id": 348
		// 				},
		// 				{
		// 					"description": "PM-CALIDAD",
		// 					"id": 19
		// 				},
		// 				{
		// 					"description": "PM-CBIO PICK",
		// 					"id": 24
		// 				},
		// 				{
		// 					"description": "PM-CENTRAL",
		// 					"id": 345
		// 				},
		// 				{
		// 					"description": "PM-CONSOLIDADO",
		// 					"id": 11
		// 				},
		// 				{
		// 					"description": "PM-COSMET",
		// 					"id": 5
		// 				},
		// 				{
		// 					"description": "PM-CTROL STOCK",
		// 					"id": 351
		// 				},
		// 				{
		// 					"description": "PM-DEV BLUSTAR",
		// 					"id": 13
		// 				},
		// 				{
		// 					"description": "PM-DEV PROV",
		// 					"id": 17
		// 				},
		// 				{
		// 					"description": "PM-DEV. I",
		// 					"id": 4
		// 				},
		// 				{
		// 					"description": "PM-DEV. II",
		// 					"id": 14
		// 				},
		// 				{
		// 					"description": "PM-DEV.3",
		// 					"id": 356
		// 				},
		// 				{
		// 					"description": "PM-ENVASADO",
		// 					"id": 3
		// 				},
		// 				{
		// 					"description": "PM-FABRICA",
		// 					"id": 354
		// 				},
		// 				{
		// 					"description": "PM-OFERTAS",
		// 					"id": 21
		// 				},
		// 				{
		// 					"description": "PM-PALLET",
		// 					"id": 353
		// 				},
		// 				{
		// 					"description": "PM-PICKING",
		// 					"id": 346
		// 				},
		// 				{
		// 					"description": "PM-PPAL",
		// 					"id": 347
		// 				},
		// 				{
		// 					"description": "PM-PREMIOS",
		// 					"id": 343
		// 				},
		// 				{
		// 					"description": "PM-PRODUCTO",
		// 					"id": 10
		// 				},
		// 				{
		// 					"description": "PM-PULMON",
		// 					"id": 355
		// 				},
		// 				{
		// 					"description": "PM-RECEP",
		// 					"id": 344
		// 				},
		// 				{
		// 					"description": "PM-RECHAZOS",
		// 					"id": 9
		// 				},
		// 				{
		// 					"description": "PM-RECICL",
		// 					"id": 15
		// 				},
		// 				{
		// 					"description": "PM-STOCK",
		// 					"id": 1
		// 				},
		// 				{
		// 					"description": "PM-TA CER",
		// 					"id": 8
		// 				},
		// 				{
		// 					"description": "STA ELE OUT",
		// 					"id": 342
		// 				}
		// 			],
		// 			"type": "Long"
		// 		},
		// 		{
		// 			"label": "Producto",
		// 			"name": "p_producto",
		// 			"options": [],
		// 			"type": "String"
		// 		},
		// 		{
		// 			"label": "Fecha",
		// 			"name": "p_fecha",
		// 			"options": [],
		// 			"type": "Date"
		// 		},
		// 		{
		// 			"label": "Talle",
		// 			"name": "p_talle",
		// 			"options": [],
		// 			"type": "String"
		// 		},
		// 		{
		// 			"label": "Descripcion",
		// 			"name": "p_descripcion",
		// 			"options": [],
		// 			"type": "String"
		// 		},
		// 		{
		// 			"label": "Checkbox",
		// 			"name": "p_bool",
		// 			"options": [],
		// 			"type": "Boolean"
		// 		},
		// 		{
		// 			"label": "Ubicacion",
		// 			"name": "p_ubicacion",
		// 			"options": [],
		// 			"type": "String"
		// 		}
		// 	],
		// 	"errors": {},
		// 	"status": "ok"
		// });
	}
}

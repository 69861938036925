import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';

import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { MenuItem } from '../_base/service/menu/menu-item.model';
import { AppInitService } from '../_base/service/app-init.service';
import { CommonModule, DOCUMENT, NgClass, NgStyle } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../_base/component/confirmation-dialog/confirmation-dialog.component';
import { LoggerService } from '../_base/service/logger/logger.service';
import { UserMenuService } from '../_base/service/menu/user-menu.service';
import { environment } from '../environments/environment';
import { MediaMatcher } from '@angular/cdk/layout';
import { SnackBarService } from '../_base/component/snack-bar/snack-bar.service';
import { AuthService } from '../_base/auth/auth.service';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgBaseComponentModule } from '../_base/component/ng-base-component.module';
import { NgBaseAngularMaterialModule } from '../_base/ng-base-angular-material.module';
import { AuthModule } from '../_base/auth/auth.module';
import { RestApiService } from '../_base/service/rest-api/rest-api.service';
import { RestAuthApiService } from '../_base/service/rest-api/rest-auth-api.service';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [MatToolbarModule,
		AuthModule,
		CommonModule,
		RouterModule,
		RouterOutlet,
		NgBaseComponentModule,
		NgBaseAngularMaterialModule],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

	ready = false;
	mobileQuery: MediaQueryList;

	menuItems: MenuItem[] = [];
	expandedItem: number = -1;
	currentMenuItem: MenuItem = null;

	username: string;
	name: string;

	@ViewChild("snav") snav: MatSidenav;

	private _mobileQueryListener: () => void;

	constructor(
		private apiService: RestApiService,
		private restAuthApiService: RestAuthApiService,
		private appInitService: AppInitService,
		private logger: LoggerService,
		private changeDetectorRef: ChangeDetectorRef,
		private media: MediaMatcher,
		private router: Router,
		private snackBar: SnackBarService,
		private dialog: MatDialog,
		private authService: AuthService,
		@Inject(DOCUMENT) private document: Document,
		private userMenuService: UserMenuService) {
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);

		if (environment.name == "stg") {
			this.document.documentElement.classList.add('theme-alternate');
		}
		this.logger.i("app.component: " + "constructor");
	}

	appInitServiceSubscription: Subscription;

	ngOnInit(): void {
		this.logger.i("app.component: " + "oninit");
		this.apiService.init(environment.baseUrl);
		this.restAuthApiService.init(environment.baseUrl);
		
		this.appInitServiceSubscription = this.appInitService.appReady$.subscribe(
			value => {
				this.ready = value;
				if (value) {
					this.menuItems = this.userMenuService.menuItems;
					this.username = this.authService.user.username;
					this.name = this.authService.user['nombre'] + " " + this.authService.user['apellido'];
					// this.logger.i("app.component: " + JSON.stringify(this.menuItems, null, 2));		
					if (!this.mobileQuery.matches) {
						this.snav.open();
					}
				} else {
					this.snav.close();
				}
			}
		);

	}

	setExpandedItem(id: number) {
		this.logger.i("app.component: " + "expanded " + id);
		if (this.expandedItem != id) {
			this.expandedItem = id;
		}
	}

	navigateTo(id: number) {
		this.logger.i("app.component: " + id);
		for (const itemN1 of this.menuItems) {
			if (itemN1.id == id) {
				this.currentMenuItem = itemN1;
				break;
			} else {
				for (const itemN2 of itemN1.items) {
					if (itemN2.id == id) {
						this.currentMenuItem = itemN2;
						break;
					}
				}
			}
		}

		this.logger.i("app.component: " + JSON.stringify(this.currentMenuItem, null, 2));
		if (this.currentMenuItem.url.indexOf("jreport/params") != -1) {
			this.router.navigate(["blank"]);
		}

		if (this.mobileQuery.matches) {
			this.snav.toggle();
		}

		setTimeout(() => {
			if (this.currentMenuItem.queryParams) {
				this.router.navigate([this.currentMenuItem.url], { queryParams: JSON.parse(this.currentMenuItem.queryParams) });
			} else {
				this.router.navigate([this.currentMenuItem.url]);
			}
		}, 500);
	}

	logout() {
		this.dialog
			.open(ConfirmationDialogComponent, {
				width: '250px',
				data: {
					title: `Atención`,
					message: `¿Desea cerrar la sesión?`
				},
				closeOnNavigation: false,
				hasBackdrop: true
			})
			.afterClosed()
			.subscribe((result: Boolean) => {
				if (result) {
					this.authService.logout();
					this.router.navigate(["auth/login"]);
					this.snackBar.show("Se ha cerrado la sesión.");
				}
			});
	}
}

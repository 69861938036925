import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JasperReportParam } from '../jasper-report-param.model';
import { JasperReportService } from '../jasper-report.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { SnackBarService } from '../../snack-bar/snack-bar.service';
import { LoadingIndicatorService } from '../../loading-indicator/loading-indicator.service';
import { OneOfTwo } from '../../form-validator/one-of-two';
import { ErrorDialogService } from '../../error-dialog/error-dialog.service';
import { JasperReport } from '../jasper-report.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { LoggerService } from '../../../service/logger/logger.service';
import { RestAuthApiService } from '../../../service/rest-api/rest-auth-api.service';


@Component({
	selector: 'app-jasper-report',
	templateUrl: './jasper-report.component.html',
	styleUrls: ['./jasper-report.component.scss']
})
export class JasperReportComponent implements OnInit {

	reportId: number;
	report: JasperReport;
	htmlEndpoint = "be/report/html";
	pdfEndpoint = "be/report/pdf";
	xlsEndpoint = "be/report/xls";

	constructor(protected logger: LoggerService,
		protected route: ActivatedRoute,
		protected snackBar: SnackBarService,
		protected formBuilder: FormBuilder,
		protected loading: LoadingIndicatorService,
		protected errorDialogService: ErrorDialogService,
		protected breakpointObserver: BreakpointObserver,
		protected service: JasperReportService,
		protected apiService: RestAuthApiService,
		public sanitizer: DomSanitizer,
		public dialog: MatDialog) {
		this.logger.i("report-params.component: constructed");
	}

	ngOnInit() {
		// this.logger.i("report-params.component: " + this.getDateSubstring());
		this.initResponsiveFilter();
		this.reportId = +this.route.snapshot.queryParamMap.get('reportId');
		this.service.getParams(this.reportId)
			.then(res => {
				if (res.status == "ok") {
					this.logger.i("auth.service: " + JSON.stringify(res, null, 2));
					this.report = res.data;
					this.createForm();
				}
				if (res.status == "error") {
					this.snackBar.showError(res.errors.general);
				}
			})
			.catch(error => {
				this.logger.e("auth.service: " + JSON.stringify(error, null, 2));
				if (error.statusText != null && error.statusText == "Unknown Error") {
					this.snackBar.showError("El servicio no está disponible. Por favor, inténtelo mas tarde.");
				}
				this.snackBar.showError(error.headers.get("x-reason"));
			})
			.finally(() => {
				this.loading.hide();
			});
	}

	paramsForm: FormGroup;
	createForm() {
		this.logger.i("report-params.component: " + "creando form");
		this.paramsForm = this.formBuilder.group({
			reportId: this.reportId,
			params: this.formBuilder.array([])
		});

		for (const param of this.report.params) {
			this.logger.i("report-params.component: " + "creando param " + JSON.stringify(param, null, 2));
			if (param.optional) {
				(<FormArray>this.paramsForm.controls.params).push(
					this.formBuilder.group({
						name: param.name,
						label: param.label,
						type: param.type,
						options: this.formBuilder.array(param.options),
						value: param.type == "Boolean" ? false : null,
						valueSet: null,
					})
				);
			} else {
				(<FormArray>this.paramsForm.controls.params).push(
					this.formBuilder.group({
						name: param.name,
						label: param.label,
						type: param.type,
						options: this.formBuilder.array(param.options),
						value: param.type == "Boolean" ? false : null,
						valueSet: null,
					}, { validators: OneOfTwo('value', 'valueSet') })
				);
			}
		}
	}

	submitted: boolean = false;
	out = "";

	onSubmit() {
		this.submitted = true;
		this.logger.i("report-params.component: " + this.out);

		// stop here if form is invalid
		if (this.paramsForm.invalid) {
			this.snackBar.show("Hay errores en el formulario.");
			return;
		}
		this.logger.i("report-params.component: " + "okkkkkkk");
		this.paramsStack.push(this.prepareParams(this.paramsForm.value));

		if (this.out == "pdf") {
			return this.downloadPdf();
		}
		if (this.out == "xls") {
			return this.downloadXls();
		}
		if (this.out == "html") {
			return this.downloadHtml();
		}
	}

	getCurrentParams() {
		return this.paramsStack[this.paramsStack.length - 1];
	}

	downloadPdf() {
		this.loading.show();
		this.apiService.getBlob(this.pdfEndpoint, this.getCurrentParams())
			.then((res: Blob) => {
				if (res) {
					let anchor = document.createElement('a');
					document.body.appendChild(anchor);
					anchor.download = this.report.name + this.getDateSubstring() + ".pdf";
					anchor.href = URL.createObjectURL(res);
					anchor.target = "_blank";
					anchor.dataset.downloadurl = [anchor.download, anchor.href].join(':');
					// window.open(anchor.href, "_blank");
					anchor.click();
					URL.revokeObjectURL(anchor.href);
					document.body.removeChild(anchor);
				} else {
					this.errorDialogService.open("Reporte sin datos", " ");
				}
			})
			.catch((error) => {
				this.errorDialogService.open(error.message);
			})
			.finally(() => this.loading.hide());
	}

	prepareParams(paramFormValue: any) {
		this.logger.i("report-params.component: " + "11111111111111111");
		this.logger.i("report-params.component: " + JSON.stringify(paramFormValue, null, 2));

		let params: any = {};
		params["reportId"] = paramFormValue.reportId;
		for (const param of paramFormValue.params) {
			this.logger.i("report-params.component: " + param.name);
			if (param.value != null) {
				if (param.options.length > 0) {
					params[param.name] = param.value.id;
					params[param.name.substring(0, param.name.lastIndexOf("Id")) + "Description"] = param.value.description;
				} else {
					switch (param.type) {
						case "TimeStamp":
							if (param.name.toLowerCase() == "desde" || param.name.toLowerCase() == "from") {
								params[param.name] = param.value.toISOString().replace("T03:00:00.000Z", "T00:00:00.000");
							}
							if (param.name.toLowerCase() == "hasta" || param.name.toLowerCase() == "to") {
								params[param.name] = param.value.toISOString().replace("T03:00:00.000Z", "T23:59:59.999");
							}
							break;
						default:
							params[param.name] = param.value;
							break;
					}
				}
			}
		}
		this.logger.i("report-params.component: " + JSON.stringify(params, null, 2));
		return params;
	}

	downloadXls() {
		this.loading.show();
		this.apiService.getBlob(this.xlsEndpoint, this.getCurrentParams())
			.then((res: Blob) => {
				if (res) {
					let anchor = document.createElement('a');
					document.body.appendChild(anchor);
					anchor.download = this.report.name + this.getDateSubstring() + ".xls";
					anchor.href = URL.createObjectURL(res);
					anchor.dataset.downloadurl = [anchor.download, anchor.href].join(':');
					anchor.click();
					URL.revokeObjectURL(anchor.href);
					document.body.removeChild(anchor);
				} else {
					this.errorDialogService.open("Reporte sin datos", " ");
				}
			})
			.catch((error) => {
				this.errorDialogService.open(error.message);
			})
			.finally(() => this.loading.hide());
	}

	paramsStack: any[] = [];
	htmlStack: string[] = [];
	downloadHtml() {
		this.logger.i("jasper-report-params.component: " + "html");
		this.loading.show();
		this.apiService.getText(this.htmlEndpoint, this.getCurrentParams())
			.then((res) => {

				this.htmlStack.push(res);
			})
			.catch((error) => {
				this.errorDialogService.open(error.message);
			})
			.finally(() => this.loading.hide());
	}

	getDateSubstring() {
		let d = new Date();
		d = new Date(d.getTime() - (d.getTimezoneOffset() * 60000));
		let text = d.toISOString();
		this.logger.i("report-params.component: " + text);
		return text.substring(0, 19).replace("T", "_").replace(/:/g, "");
	}

	@HostListener('document:subreport', ['$event.detail'])
	subreport(detail) {
		this.logger.i("jasper-report-params.component: " + "lalalaal");
		if(detail){
			this.logger.i("jasper-report-params.component: " + JSON.stringify(detail, null, 2));
			let params = JSON.parse(JSON.stringify(this.paramsStack[this.paramsStack.length - 1]));
			for (let key in detail) {
				params[key] = detail[key];
			}
			params["reportId"] = detail["subreportId"];
			this.paramsStack.push(params);
			this.downloadHtml();
		}
	}

	back() {
		this.paramsStack.pop();
		this.htmlStack.pop();
		this.subreport(null);
	}

	//#region		RESPONSIVE FILTER
	handsetPortraitMode: boolean;
	breakpointObservable: Subscription;

	initResponsiveFilter() {
		this.handsetPortraitMode = this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait);
		this.breakpointObservable = this.breakpointObserver.observe(Breakpoints.HandsetPortrait).subscribe(result => {
			this.handsetPortraitMode = result.matches;
		});
	}
	//#endregion	RESPONSIVE FILTER		
}

import { Injectable } from '@angular/core';
import { MenuItem } from './menu-item.model';
import { LoggerService } from '../logger/logger.service';
import { RestAuthApiService } from '../rest-api/rest-auth-api.service';
import { ResponseObject } from '../rest-api/response-object.model';

@Injectable({
	providedIn: 'root'
})
export class UserMenuService {

	endpoint = "be/user-menu";

	menuItems: MenuItem[];

	constructor(protected logger: LoggerService,
		protected apiService: RestAuthApiService) {
		this.logger.i("menu.service: constructed");
	}

	init(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.logger.i("menu.service: " + "begin init");
			this.apiService.get(this.endpoint)
				.then((res: ResponseObject) => {
					if (res.status == "ok") {
						this.menuItems = res.data.items;
						this.logger.i("menu.service: " + "end init");
						resolve(true);
					} else {
						reject(res.errors.general);
					}
				})
				.catch((error) => {
					this.logger.i("menu.service: " + JSON.stringify(error, null, 2));
					reject(error);
				});
		});
	}
	
}

import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from './loading-indicator.service';
import { LoggerService } from '../../service/logger/logger.service';

@Component({
	selector: 'ng-base-loading-indicator',
	templateUrl: './loading-indicator.component.html',
	styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {

	shown: boolean = false;

	constructor(private logger: LoggerService,
				private loadingService: LoadingIndicatorService) {
		this.logger.i("loading-indicator.component: constructed");
	}

	ngOnInit() {
		this.loadingService.loadingObservable.subscribe((shown) => {
			this.shown = shown;
		});
	}

}

import { Routes } from '@angular/router';
import { AuthGuard } from '../_base/auth/auth.guard';
import { DialogGuard } from '../_base/auth/dialog.guard';
import { BlankComponent } from '../_base/component/blank/blank.component';


export const routes: Routes = [
	{
		path: 'home',
		pathMatch: 'full',
		canActivate: [AuthGuard],
		canDeactivate: [DialogGuard],
		component: BlankComponent
	},
	{
		path: 'auth',
		loadChildren: () => import('../_base/auth/auth.module').then(m => m.AuthModule)
	},
	{
		path: 'admin',
		loadChildren: () => import('./be-admin/be-admin.module').then(m => m.BEAdminModule)
	},
	{
		path: 'administracion',
		loadChildren: () => import('./administracion/administracion.module').then(m => m.AdministracionModule)
	},
	{
		path: 'sistema',
		loadChildren: () => import('./sistema/sistema.module').then(m => m.SistemaModule)
	},
	{
		path: '',
		redirectTo: 'auth/login',
		pathMatch: 'full'
	},
];

import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';
import { LoggerService } from '../_base/service/logger/logger.service';
import { LoggerDebugService } from '../_base/service/logger/logger-debug.service';
import { AppInitService } from '../_base/service/app-init.service';
import { AppInitImplService } from './app-init-impl.service';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from '../_base/util/custom-mat-paginator-intl';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DateAdapterDDMM } from '../_base/util/date-adapter-ddmm';

export const appConfig: ApplicationConfig = {
	providers: [provideRouter(routes),
	provideAnimationsAsync(),
	provideHttpClient(),
	{ provide: LoggerService, useClass: LoggerDebugService },
	{ provide: AppInitService, useClass: AppInitImplService },
	{ provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
	{
		provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: {
			formFieldAppearance: 'fill'
		}
	},
	{
		provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
			hasBackdrop: true,
			closeOnNavigation: false,
			disableClose: true
		}
	},
	{ provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
	{ provide: DateAdapter, useClass: DateAdapterDDMM }, provideAnimationsAsync(),
	]
};

import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable()
export class LoggerDebugService extends LoggerService {

	override d(value: string) {
		console.debug(value);
	}

	override i(value: string) {
		console.info(value);
	}

	override w(value: string) {
		console.warn(value);
	}

	override e(value: string) {
		console.error(value);
	}

}
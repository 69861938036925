import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../../service/logger/logger.service';
import { SnackBarService } from './snack-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'ng-base-snack-bar',
	templateUrl: './snack-bar.component.html',
	styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {

	constructor(private logger: LoggerService,
				private snackBarService: SnackBarService,
				private snackBar: MatSnackBar) {
		this.logger.i("snack-bar.component: constructed");

	}

	ngOnInit() {
		this.snackBarService.snackBarObservable.subscribe((object)=>{	
			if(object.error){
				this.snackBar.open(object.message, 'OK', {panelClass: "snack-bar-error"});	
			}else{
				this.snackBar.open(object.message, 'OK', {duration: 4000});			
			}
		});
	}

}

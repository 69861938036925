import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function OneOfTwo(controlName1: string, controlName2: string) {
    return (formGroup: FormGroup) => {
        const control1 = formGroup.controls[controlName1];
        const control2 = formGroup.controls[controlName2];
// console.log("1111 validando");
// console.log(control1.value);
// console.log(control2.value);
		if ((control1.value == null || control1.value.toString().trim() == "")
			 && 
			(control2.value == null || control2.value.toString().trim() == "")) {
				// console.log("1111 error");
				control1.setErrors({ oneOfTwo: true });
				control2.setErrors({ oneOfTwo: true });
        } else {
            control1.setErrors(null);
            control2.setErrors(null);
        }
    }
}

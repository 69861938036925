import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
	title: string,
	message: string,
	fatal: boolean
}

@Component({
	selector: 'ng-base-error-dialog',
	templateUrl: './error-dialog.component.html',
	styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

	
	constructor(@Optional()@Inject(MAT_DIALOG_DATA) public data: DialogData) { 
	
	}
}

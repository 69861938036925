<div class="main-container" [class.main-is-mobile]="mobileQuery.matches">
	<mat-toolbar color="primary" class="main-toolbar" [ngClass]="{'invisible': !ready}">
		
		<span class="menu-button-container">
			<button mat-icon-button (click)="snav.toggle()">
				<mat-icon>menu</mat-icon>
			</button>
		</span>
		<span class="logo-container">
			<img src="assets/images/logo-small.png" class="logo">
		</span>

		<span class="spacer"></span>

		<span *ngIf="username" class="username">
			{{username}}				
		</span>
		<span *ngIf="username">
			<button mat-icon-button [matMenuTriggerFor]="userMenu">
				<mat-icon>account_circle</mat-icon>
			</button>
			<mat-menu #userMenu="matMenu">
				<button mat-menu-item (click)="logout()">
					<mat-icon color="primary">logout</mat-icon>
					<span>Cerrar sesión</span>
				</button>				
			</mat-menu>
		</span>
		
	</mat-toolbar>
	<mat-sidenav-container class="main-sidenav-container">
		<mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" class="main-menu">
			<!-- <mat-nav-list [ngClass]="{'invisible': !ready}">
				<a mat-list-item routerLink="." *ngFor="let nav of fillerNav">{{nav}}</a>
			</mat-nav-list> -->
			<mat-nav-list >

				<ng-container *ngFor="let item of menuItems">
					<mat-accordion  *ngIf="item.items.length > 0" hideToggle="false" multi="false" >						
						
						<mat-expansion-panel class="menu" 
											[expanded]="expandedItem == item.id" 
											(click)="setExpandedItem(item.id)">
							<mat-expansion-panel-header>
								<mat-panel-description>
									<mat-list-item>
										<mat-icon *ngIf="item.icon" matListItemIcon>{{item.icon}}</mat-icon>
										<div matListItemTitle>{{item.label}}</div>
									</mat-list-item>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<a mat-list-item *ngFor="let item2 of item.items"
								(click)="navigateTo(item2.id)"
								[ngClass]="{'current-menu-item': currentMenuItem != null && item2.id == currentMenuItem.id }"
								[ngStyle]="{'background': item2.color ? item2.color : 'unset' }"
								class="inner-menu-item">
								<div class="inner-menu-item">{{item2.label}}</div>
							</a>						
						</mat-expansion-panel>
						
					</mat-accordion>

					<ng-container *ngIf="item.items.length == 0">						
						<a mat-list-item [routerLink]="item.url">{{item.label}}</a>
					</ng-container>
				</ng-container>

			</mat-nav-list>
		</mat-sidenav>

		<mat-sidenav-content>
			<!-- <p *ngFor="let content of fillerContent">{{content}}</p> -->
			<router-outlet></router-outlet>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>

<ng-base-loading-indicator></ng-base-loading-indicator>
<ng-base-snack-bar></ng-base-snack-bar>
<ng-base-error-dialog-container></ng-base-error-dialog-container>
